import {
  Equipment,
  EquipmentPerformance,
  FilterTimePeriod,
} from '@energybox/react-ui-library/dist/types';
import {
  determineWhichEnergyApiFunctionToUse,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentEnergyPowerBySiteId } from '../actions/energy';
import { getTSEquipmentEnergyPowerBySiteId } from '../actions/energyTS';
import {
  getEquipment,
  getEquipmentBySiteId,
  getEquipmentCountPerGroupId,
  getEquipmentPerformanceByGroup,
  getEquipmentPerformanceByGroupAsync,
  getEquipmentPerformanceByGroupAsyncId,
  getHvacControlGroupCount,
} from '../actions/equipment';
import { ApplicationState } from '../reducers';
import { RecentPowerByEquipmentId } from '../reducers/energy';
import {
  EquipmentById,
  EquipmentCounts,
  EquipmentPerformanceReport,
  EquipmentPerformanceReportStatus,
  Equipments,
} from '../reducers/equipment';
import { sanitizeApiLoadingState } from '../util';
import { useOrganizationId } from './useCurrentUser';
import useIsNewTsdb from './useIsNewTsdb';
import { useComponentLoadingStatus } from './utils';

export type EquipmentListStatus = {
  isLoading: boolean;
  data?: Equipment[];
  equipmentById: EquipmentById;
  equipmentIds: number[];
};

export type PowersByEquipmentIdStatus = {
  isLoading: boolean;
  data?: RecentPowerByEquipmentId;
};

export function useEquipmentsBySiteId(
  siteId: string | number
): EquipmentListStatus {
  const equipments: Equipment[] | undefined = useSelector(
    (state: { equipment: Equipments }) => {
      return pathOr(undefined, [siteId], state.equipment.equipmentBySiteId);
    }
  );

  const equipmentById: EquipmentById = useSelector(
    (state: { equipment: Equipments }) => state.equipment.equipmentById
  );

  const equipmentIds: number[] = useSelector(
    (state: { equipment: Equipments }) =>
      state.equipment.equipmentIdsBySiteId[siteId] || []
  );

  const isLoading: boolean | undefined = useSelector(
    (state: { equipment: Equipments }) => {
      return pathOr(undefined, [siteId], state.equipment.isLoadingBySiteId);
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading === undefined) {
      dispatch(getEquipmentBySiteId(siteId));
    }
  }, [isLoading, dispatch, siteId]);

  return {
    isLoading: sanitizeApiLoadingState(isLoading),
    data: equipments,
    equipmentById,
    equipmentIds,
  };
}

export const useEquipmentPowersBySiteId = (
  siteId: string | number
): PowersByEquipmentIdStatus => {
  const powersByEquipmentId: RecentPowerByEquipmentId | undefined = useSelector(
    (state: ApplicationState) => {
      return pathOr(
        undefined,
        [siteId.toString()],
        state.energy.equipmentPowerBySiteId
      );
    }
  );

  const isLoading: boolean | undefined = useSelector(
    (state: ApplicationState) => {
      return state.energy.isLoading;
    }
  );
  const isNewTsdb = useIsNewTsdb(siteId);

  const dispatch = useDispatch();
  useEffect(() => {
    const getEquipmentPower = determineWhichEnergyApiFunctionToUse(
      isNewTsdb,
      getEquipmentEnergyPowerBySiteId,
      getTSEquipmentEnergyPowerBySiteId
    );

    dispatch(getEquipmentPower(siteId));
  }, [dispatch, isNewTsdb, siteId]);

  return useMemo(
    () => ({
      isLoading: isLoading,
      data: powersByEquipmentId,
    }),
    [powersByEquipmentId, isLoading]
  );
};

export function useEquipmentPerformance(
  equipmentGroupId: number,
  timePeriod: FilterTimePeriod
): { isLoading: boolean; data: undefined | EquipmentPerformance } {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();
  const performance = useSelector<ApplicationState, EquipmentPerformanceReport>(
    ({ equipment }) => equipment.performance
  );
  if (
    !performance.isLoading &&
    performance.performanceByGroupId[equipmentGroupId] !== undefined
  ) {
    return {
      isLoading: performance.isLoading,
      data: performance.performanceByGroupId[equipmentGroupId],
    };
  } else {
    if (orgId !== undefined) {
      dispatch(
        getEquipmentPerformanceByGroup(
          orgId,
          equipmentGroupId,
          timePeriod.fromDate,
          timePeriod.toDate
        )
      );
    }
    return {
      isLoading: true,
      data: undefined,
    };
  }
}

export function useEquipmentPerformanceAsync(
  equipmentGroupId: number,
  timePeriod: FilterTimePeriod
): {
  isLoading: boolean;
  data: undefined | EquipmentPerformance;
  loading: number;
  secondsLoaded: number;
} {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();
  const performance = useSelector<ApplicationState, EquipmentPerformanceReport>(
    ({ equipment }) => equipment.performance
  );
  const performanceStatus = useSelector<
    ApplicationState,
    EquipmentPerformanceReportStatus
  >(({ equipment }) => equipment.performanceReportStatus);
  const [secondsLoaded, setSecondsLoaded] = useState(0);
  const [secondsForCalc, setSecondsForCalc] = useState(0);

  useMemo(() => {
    setSecondsForCalc(secondsLoaded);
  }, [performanceStatus.loading]);

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  useEffect(() => {
    if (orgId !== undefined && siteIdsStr !== '') {
      dispatch(
        getEquipmentPerformanceByGroupAsyncId(
          orgId,
          equipmentGroupId,
          timePeriod.fromDate,
          timePeriod.toDate,
          siteIdsStr
        )
      );
    }
  }, [
    dispatch,
    orgId,
    equipmentGroupId,
    timePeriod.fromDate,
    timePeriod.toDate,
    siteIdsStr,
  ]);

  useEffect(() => {
    let interval: any;
    if (
      orgId !== undefined &&
      performanceStatus.reportId !== undefined &&
      performance.performanceByGroupId[equipmentGroupId] === undefined
    ) {
      dispatch(
        getEquipmentPerformanceByGroupAsync(
          orgId,
          equipmentGroupId,
          performanceStatus.reportId || ''
        )
      );
      interval = setInterval(() => {
        dispatch(
          getEquipmentPerformanceByGroupAsync(
            orgId,
            equipmentGroupId,
            performanceStatus.reportId || ''
          )
        );
        setSecondsLoaded((secondsLoaded) => secondsLoaded + 4);
      }, 4000);
      return () => clearInterval(interval);
    }

    if (performance.performanceByGroupId[equipmentGroupId] !== undefined) {
      clearInterval(interval);
      setSecondsLoaded(0);
    }
  }, [
    dispatch,
    orgId,
    equipmentGroupId,
    performanceStatus.reportId,
    performance.performanceByGroupId,
  ]);

  if (
    performance !== undefined &&
    !performance.isLoading &&
    performance.performanceByGroupId[equipmentGroupId] !== undefined
  ) {
    return {
      isLoading: performance.isLoading,
      data: performance.performanceByGroupId[equipmentGroupId],
      loading:
        performanceStatus.reportVersion >= 0 ? performanceStatus.loading : 0,
      secondsLoaded: secondsLoaded,
    };
  } else {
    return {
      isLoading: true,
      data: undefined,
      loading:
        performanceStatus.reportVersion >= 0 ? performanceStatus.loading : 0,
      secondsLoaded: secondsForCalc,
    };
  }
}

export const useEquipmentSelector = () => {
  const { isLoading, equipment: equipments }: Equipments = useSelector(
    ({ equipment }: ApplicationState) => equipment
  );
  return {
    isLoading,
    equipments,
  };
};

export const useEquipmentById = (equipmentId: string | number | undefined) => {
  const dispatch = useDispatch();

  const equipment = useSelector<ApplicationState, Equipment | undefined>(
    ({ equipment }) => {
      if (isDefined(equipmentId)) {
        return equipment.equipmentById[equipmentId];
      }
      return undefined;
    }
  );

  useEffect(() => {
    if (isDefined(equipmentId) && !isDefined(equipment)) {
      dispatch(getEquipment({ ids: [Number.parseInt(String(equipmentId))] }));
    }
  }, [dispatch, equipmentId]);

  return equipment;
};

export const useEquipmentCountSortedByGroupId = () => {
  const { byGroupIsLoading, byGroupId }: EquipmentCounts = useSelector(
    ({ equipment }: ApplicationState) => equipment.equipmentCounts
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (byGroupId === undefined) dispatch(getEquipmentCountPerGroupId());
  }, [dispatch, byGroupId]);
  const isLoading = useComponentLoadingStatus(byGroupIsLoading);
  return {
    isLoading,
    equipmentCountsByGroupId: byGroupId || {},
  };
};

export const useHvacControlEquipmentCount = () => {
  const { byGroupIsLoading, byGroupId }: EquipmentCounts = useSelector(
    ({ equipment }: ApplicationState) => equipment.hvacEquipmentCount
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (byGroupId === undefined) dispatch(getHvacControlGroupCount());
  }, [dispatch, byGroupId]);

  return {
    byGroupIsLoading,
    hvacEquipmentCount: byGroupId || {},
  };
};
